import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AwesomeDebouncePromise from "awesome-debounce-promise";

import {
  updateValidatedCalorieIntake,
  updateErrors,
} from "actions/calorie_target.actions";
import { betweenZeroAnd } from "services/validators";

import FormInput from "components/form_input";

const CalorieIntake = ({
  validatedCalorieIntake,
  validatedCalorieIntakeErrors,
  updateValidatedCalorieIntake,
  updateErrors,
  staffRole,
}) => {
  const _validate = (newCalorieTarget) => {
    if (betweenZeroAnd(10000, newCalorieTarget)) {
      updateErrors("validatedCalorieTarget", null);
    } else {
      updateErrors("validatedCalorieTarget", "Must be between 0 and 9,999");
    }
  };
  const validate = AwesomeDebouncePromise(_validate, 300);

  const handleChange = (e) => {
    const value = e.target.value.replace(/\s/g, "");
    updateValidatedCalorieIntake(value);
    validate(value);
  };

  return (
    <div className="pure-g">
      <div className="pure-u-1 pure-u-lg-1-2">
        <FormInput
          name="validated_calorie_intake"
          label="Validated Calorie Intake"
          labelClass="strong"
          value={validatedCalorieIntake || (staffRole === "hcp" ? "N/A" : "")}
          error={validatedCalorieIntakeErrors}
          onChange={handleChange}
          readOnly={staffRole === "hcp"}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const calorieTarget = state.calorieTarget;
  return {
    validatedCalorieIntake: calorieTarget.validatedCalorieIntake,
    validatedCalorieIntakeErrors: calorieTarget.errors.validatedCalorieTarget,
    staffRole: calorieTarget.staffRole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateValidatedCalorieIntake: updateValidatedCalorieIntake,
      updateErrors: updateErrors,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CalorieIntake);
