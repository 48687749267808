function responsiveSidebar(sidebar) {
  const activeClass = "responsive-sidebar-active";
  const layout = document.getElementById("layout-wrapper");
  const menuLink = document.getElementById("hamburger");
  const content = document.getElementById("main");

  function toggleClass(element, className) {
    element.classList.toggle(className);
  }

  function toggleSidebar(e) {
    e.preventDefault();
    toggleClass(layout, activeClass);
    toggleClass(sidebar, activeClass);
    toggleClass(menuLink, activeClass);
  }

  menuLink.onclick = function (e) {
    toggleSidebar(e);
  };

  content.onclick = function (e) {
    if (sidebar.className.indexOf(activeClass) !== -1) {
      toggleSidebar(e);
    }
  };
}

export default responsiveSidebar;
