import { postRequest } from "./base_requests";

export function createCalorieTarget(url, form) {
  return postRequest({
    url: url,
    body: form,
  });
}

export function createPartialCalorieTarget(
  url,
  weight,
  height,
  validatedCalorieIntake
) {
  return postRequest({
    url: url,
    body: {
      partial_calorie_target: {
        weight: weight,
        height: height,
        validated_calorie_intake: validatedCalorieIntake,
      },
    },
  });
}
