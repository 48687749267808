import React from "react";

const FormRadio = (props) => {
  return (
    <div className={props.wrapperClass} id={props.name}>
      <p>{props.label}</p>
      {props.options.map((option, index) => {
        const id = `${props.name}-${index}`;
        return (
          <label htmlFor={id} className="pure-radio" key={id}>
            <input
              type="radio"
              id={id}
              name={props.name}
              value={option.value}
              checked={props.selectedOption === option.value}
              onChange={props.onChange}
            />
            {option.label}
          </label>
        );
      })}
    </div>
  );
};

export default FormRadio;
