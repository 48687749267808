export const updateWeight = (newWeight) => ({
  type: "UPDATE_WEIGHT",
  newWeight: newWeight,
});

export const updateHeight = (newHeight) => ({
  type: "UPDATE_HEIGHT",
  newHeight: newHeight,
});

export const updateAlsfrs6Data = (field, value) => ({
  type: "UPDATE_ALSFRS_DATA",
  field: field,
  value: value,
});

export const updateCalorieTarget = (newCalorieTarget) => ({
  type: "UPDATE_CALORIE_TARGET",
  newCalorieTarget: newCalorieTarget,
});

export const updateValidatedCalorieIntake = (newValidatedCalorieIntake) => ({
  type: "UPDATE_VALIDATED_CALORIE_INTAKE",
  newValidatedCalorieIntake: newValidatedCalorieIntake,
});

export const updateErrors = (key, error) => ({
  type: "UPDATE_ERRORS",
  key: key,
  error: error,
});

export const updatePreStudyWeightEntry = (key, value, index) => ({
  type: "UPDATE_PRE_STUDY_WEIGHT_ENTRY",
  key: key,
  value: value,
  index: index,
});

export const updateWeightEntryErrors = (key, errorMessage, index) => ({
  type: "UPDATE_WEIGHT_ENTRY_ERRORS",
  key: key,
  errorMessage: errorMessage,
  index: index,
});

export const updateLatestWeightEntry = (latestWeightEntry) => ({
  type: "UPDATE_LATEST_WEIGHT_ENTRY",
  latestWeightEntry: latestWeightEntry,
});

export const setCalorieTarget = (url, form) => ({
  type: "SET_CALORIE_TARGET",
  url: url,
  form: form,
});

export const setPartialCalorieTarget = (url, form) => ({
  type: "SET_PARTIAL_CALORIE_TARGET",
  url: url,
  weight: form.weight,
  height: form.height,
  validatedCalorieIntake: form.validatedCalorieIntake,
});
