// Source:
// https://www.als.org/navigating-als/living-with-als/therapies-care/maintaining-adequate-nutrition-continuing-challenge

const kasarskisMale = (weight_kg, height_cm, age, alsfrs6) => {
  const result =
    66 + 13.7 * weight_kg + 5 * height_cm - 6.76 * age + 55.96 * alsfrs6 - 168;
  return result;
};

const kasarskisFemale = (weight_kg, height_cm, age, alsfrs6) => {
  const result =
    655 + 9.6 * weight_kg + 1.8 * height_cm - 4.7 * age + 55.96 * alsfrs6 - 168;
  return result;
};

export const kasarskis = (sex, weight_kg, height_cm, age, alsfrs6) => {
  const weight = parseFloat(weight_kg);
  const height = parseFloat(height_cm);
  if (
    sex &&
    Number.isFinite(weight) &&
    Number.isFinite(height) &&
    age &&
    Number.isFinite(alsfrs6)
  ) {
    const sexFunction = sex == "male" ? kasarskisMale : kasarskisFemale;
    return Math.round(sexFunction(weight, height, age, alsfrs6));
  } else {
    return null;
  }
};

export const bmi = (heightCm, weightKg) => {
  const weight = parseFloat(weightKg);
  const height = parseFloat(heightCm);
  if (isNaN(height) || isNaN(weight)) {
    return null;
  } else {
    const heightM = height / 100.0;
    return (weight / (heightM * heightM)).toFixed(2);
  }
};

const smallGapBetween = (first, second, gap = 0.001) => {
  return Math.abs(first - second) < gap;
};

export const adjustedTarget = (
  heightCm,
  currentWeightKg,
  previousWeightKg,
  kasarskis,
  calorieIntake
) => {
  if (
    isNaN(parseFloat(heightCm)) ||
    isNaN(parseFloat(currentWeightKg)) ||
    !kasarskis
  ) {
    return null;
  }

  calorieIntake = parseFloat(calorieIntake);
  if (isNaN(calorieIntake)) {
    calorieIntake = null;
  }

  if (!calorieIntake) {
    return kasarskis;
  }

  const currentBmi = bmi(heightCm, currentWeightKg);
  if (currentBmi > 35) {
    return calorieIntake;
  }

  if (previousWeightKg) {
    const weightDiff = currentWeightKg - previousWeightKg;

    if (smallGapBetween(weightDiff, 2.0)) {
      if (currentBmi < 30) {
        return Math.max(kasarskis, calorieIntake + 400.0);
      } else {
        return calorieIntake;
      }
    } else if (weightDiff > 2.0) {
      return calorieIntake;
    } else {
      return Math.max(kasarskis, calorieIntake + 400.0);
    }
  } else {
    return Math.max(calorieIntake, kasarskis);
  }
};
