import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AwesomeDebouncePromise from "awesome-debounce-promise";

import {
  updateCalorieTarget,
  updateErrors,
} from "actions/calorie_target.actions";
import { positiveAndLessThan } from "services/validators";

import FormInput from "components/form_input";

const CalorieTargetInput = ({
  calorieTarget,
  updateCalorieTarget,
  disabled,
  calorieTargetErrors,
  updateErrors,
}) => {
  const _validate = (newCalorieTarget) => {
    if (positiveAndLessThan(10000, newCalorieTarget)) {
      updateErrors("calorieTarget", null);
    } else {
      updateErrors("calorieTarget", "Must be between 1 and 9,999");
    }
  };
  const validate = AwesomeDebouncePromise(_validate, 300);

  const handleChange = (e) => {
    const value = e.target.value.replace(/\s/g, "");
    updateCalorieTarget(value);
    validate(value);
  };

  return (
    <FormInput
      name="calorie_target"
      label="Calorie Target:"
      labelClass="strong"
      value={calorieTarget}
      onChange={handleChange}
      error={calorieTargetErrors}
      disabled={disabled}
    />
  );
};

const mapStateToProps = (state) => {
  const calorieTarget = state.calorieTarget;
  const disabled =
    calorieTarget.errors.height ||
    !calorieTarget.height ||
    calorieTarget.errors.weight ||
    !calorieTarget.weight ||
    !Number.isFinite(calorieTarget.alsfrs6);
  return {
    calorieTarget: calorieTarget.calorieTarget,
    calorieTargetErrors: calorieTarget.errors.calorieTarget,
    disabled: disabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateCalorieTarget: updateCalorieTarget,
      updateErrors: updateErrors,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CalorieTargetInput);
