import React from "react";

const FormInput = React.forwardRef((props, ref) => {
  return (
    <div className={props.wrapperClass}>
      <label htmlFor={props.name} className={props.labelClass}>
        {props.label}
      </label>
      <input
        ref={ref}
        type={props.type || "text"}
        id={props.name}
        name={props.name}
        value={props.value}
        min={props.min}
        onChange={props.onChange}
        onKeyPress={props.onKeyPress}
        className={
          props.error ? `input-error ${props.className}` : props.className
        }
        readOnly={props.readOnly}
        disabled={props.disabled}
        autoComplete="off"
        placeholder={props.placeholder}
      />
      {renderError(props.error)}
    </div>
  );
});

function renderError(error) {
  return error ? <span className="error">{error}</span> : "";
}

export default FormInput;
