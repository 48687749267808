import React from "react";

import FormRadio from "components/form_radio";

const ALSFRS_QUESTIONS = [
  {
    label: "Speech",
    name: "q1",
    options: [
      { value: "4", label: "Normal speech processes" },
      { value: "3", label: "Detectable speech disturbance" },
      { value: "2", label: "Intelligible with repeating" },
      { value: "1", label: "Speech combined with non-vocal communication" },
      { value: "0", label: "Loss of useful speech" },
    ],
  },
  {
    label: "Handwriting (with dominant hand prior to ALS onset)",
    name: "q4",
    options: [
      { value: "4", label: "Normal" },
      { value: "3", label: "Slow or sloppy; all words are legible" },
      { value: "2", label: "Not all words are legible" },
      { value: "1", label: "Able to grip pen, but unable to write" },
      { value: "0", label: "Unable to grip pen" },
    ],
  },
  {
    label: "Dressing and Hygiene",
    name: "q6",
    options: [
      { value: "4", label: "Normal function" },
      {
        value: "3",
        label:
          "Independent and complete self-care with effort or decreased efficiency",
      },
      { value: "2", label: "Intermittent assistance or substitute methods" },
      { value: "1", label: "Needs attendant for self-care" },
      { value: "0", label: "Total dependence" },
    ],
  },
  {
    label: "Turning in Bed/Adjusting Bed Clothes",
    name: "q7",
    options: [
      { value: "4", label: "Normal" },
      { value: "3", label: "Somewhat slow and clumsy, but no help needed" },
      {
        value: "2",
        label: "Can turn alone or adjust sheets, but with great difficulty",
      },
      {
        value: "1",
        label: "Can initiate, but cannot turn or adjust sheets alone",
      },
      { value: "0", label: "Helpless" },
    ],
  },
  {
    label: "Walking",
    name: "q8",
    options: [
      { value: "4", label: "Normal" },
      { value: "3", label: "Early ambulation difficulties" },
      {
        value: "2",
        label: "Walks with assistance (any assistive devices including AFOs)",
      },
      { value: "1", label: "Nonambulatory functional movement only" },
      { value: "0", label: "No purposeful leg movement" },
    ],
  },
  {
    label: "Dyspnea",
    name: "q10",
    options: [
      { value: "4", label: "None" },
      { value: "3", label: "Occurs when walking" },
      {
        value: "2",
        label:
          "Occurs with one or more of the following: eating, bathing, dressing (ADL)",
      },
      {
        value: "1",
        label:
          "Occurs at rest, difficulty breathing when either sitting or lying",
      },
      {
        value: "0",
        label:
          "Significant difficulty, considering using mechanical respiratory support",
      },
    ],
  },
];

const Alsfrs = ({ alsfrs6Data, updateAlsfrs6Data }) => {
  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    updateAlsfrs6Data(e.target.name, e.target.value);
  };

  return (
    <>
      {ALSFRS_QUESTIONS.map((question) => {
        return (
          <FormRadio
            key={question.name}
            name={question.name}
            label={question.label}
            options={question.options}
            selectedOption={alsfrs6Data[question.name]}
            onChange={handleChange}
            wrapperClass="radio-buttons"
          />
        );
      })}
    </>
  );
};

export default Alsfrs;
