import React from "react";
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import SentryErrorBoundary from "components/Sentry/SentryErrorBoundary";

import { bmi } from "services/calorie_target_calculator";

import { CalorieTargetMiddleware } from "middleware/calorie_target";
import CalorieTargetReducer, {
  calorieTargetDefaultState,
} from "reducers/calorie_target.reducer";
import PreStudyWeightEntriesReducer from "reducers/pre_study_weight_entries.reducer";
import CalorieTargetForm from "components/calorie_target/calorie_target_form";

const CalorieTargetPage = (props) => {
  const store = createStore(
    combineReducers({
      calorieTarget: CalorieTargetReducer,
      preStudyWeightEntries: PreStudyWeightEntriesReducer,
    }),
    {
      calorieTarget: {
        ...calorieTargetDefaultState,
        staffRole: props.staffRole,
        saveCalorieTargetPath: props.saveCalorieTargetPath,
        savePartialCalorieTargetPath: props.savePartialCalorieTargetPath,
        weight: props.weight,
        height: props.height || "",
        previousWeight: props.previousWeight,
        sex: props.sex,
        age: props.age,
        currentCalorieIntake: props.currentCalorieIntake,
        validatedCalorieIntake: props.currentCalorieIntake,
        bmi: bmi(props.height, props.weight),
      },
      preStudyWeightEntries: props.weight
        ? []
        : [
            { date: "", weight: "", errors: { date: null, weight: null } },
            { date: "", weight: "", errors: { date: null, weight: null } },
            { date: "", weight: "", errors: { date: null, weight: null } },
          ],
    },
    applyMiddleware(CalorieTargetMiddleware)
  );

  return (
    <SentryErrorBoundary>
      <Provider store={store}>
        <CalorieTargetForm />
      </Provider>
    </SentryErrorBoundary>
  );
};

export default CalorieTargetPage;
