import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AwesomeDebouncePromise from "awesome-debounce-promise";

import {
  updatePreStudyWeightEntry,
  updateWeightEntryErrors,
  updateLatestWeightEntry,
} from "actions/calorie_target.actions";
import { positiveAndLessThan } from "services/validators";

import FormInput from "components/form_input";

const PreStudyWeightEntry = ({
  index,
  date,
  weight,
  weightError,
  dateError,
  preStudyWeightEntries,
  updatePreStudyWeightEntry,
  updateErrors,
  updateLatestWeightEntry,
}) => {
  const _validateWeight = (newWeight) => {
    if (positiveAndLessThan(1000, newWeight)) {
      updateErrors("weight", null, index);
    } else {
      updateErrors("weight", "Must be between 1 and 999", index);
    }
  };
  const validateWeight = AwesomeDebouncePromise(_validateWeight, 300);

  const _validateDate = (date) => {
    if (date) {
      updateErrors("date", null, index);
    } else {
      updateErrors("date", "can't be blank", index);
    }
  };
  const validateDate = AwesomeDebouncePromise(_validateDate, 300);

  const handleWeightChange = (e) => {
    const value = e.target.value.replace(/\s/g, "");
    updatePreStudyWeightEntry("weight", value, index);
    validateWeight(value);
    validateDate(date);

    let newEntries = [...preStudyWeightEntries];
    newEntries[index] = { ...preStudyWeightEntries[index], weight: value };
    updateLatestWeightEntry(findLatestWeight(newEntries));
  };

  const findLatestWeight = (newEntries) => {
    const entries = newEntries.filter((entry) => {
      return entry.date && entry.weight;
    });

    if (entries.length != 0) {
      const weight = entries.sort(
        (entry1, entry2) => entry2.date - entry1.date
      )[0].weight;
      return weight;
    } else {
      return null;
    }
  };

  const handleDateChange = (date) => {
    updatePreStudyWeightEntry("date", date, index);
    validateWeight(weight);
    validateDate(date);
  };

  return (
    <div className="pure-g">
      <div className="pure-u-1 pure-u-lg-1-2">
        <label htmlFor={`date${index}`} className="strong">
          Date:
        </label>
        <DatePicker
          name={`date${index}`}
          dateFormat="dd/MM/yyyy"
          autoComplete="off"
          selected={date}
          onChange={(date) => handleDateChange(date)}
          maxDate={new Date()}
          showDisabledMonthNavigation
          className={dateError ? `input-error` : ""}
        />
        {dateError && <span className="error">{dateError}</span>}
      </div>
      <div className="pure-u-1 pure-u-lg-1-2">
        <FormInput
          name={`weight${index}`}
          label="Weight (kg):"
          labelClass="strong"
          className="short"
          value={weight}
          onChange={(e) => handleWeightChange(e)}
          error={weightError}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const index = ownProps.index;
  const entry = state.preStudyWeightEntries[index];
  return {
    index: index,
    date: entry.date,
    weight: entry.weight,
    dateError: entry.errors.date,
    weightError: entry.errors.weight,
    preStudyWeightEntries: state.preStudyWeightEntries,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updatePreStudyWeightEntry: updatePreStudyWeightEntry,
      updateErrors: updateWeightEntryErrors,
      updateLatestWeightEntry: updateLatestWeightEntry,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreStudyWeightEntry);
