import {
  kasarskis,
  adjustedTarget,
  bmi,
} from "services/calorie_target_calculator";

export const calorieTargetDefaultState = {
  staffRole: undefined,
  saveCalorieTargetPath: undefined,
  savePartialCalorieTargetPath: undefined,
  weight: undefined,
  height: "",
  previousWeight: undefined,
  sex: undefined,
  age: undefined,
  currentCalorieIntake: undefined,
  validatedCalorieIntake: undefined,
  alsfrs6: null,
  alsfrs6Data: {
    q1: null,
    q4: null,
    q6: null,
    q7: null,
    q8: null,
    q10: null,
  },
  kasarskis: null,
  bmi: null,
  suggestedCalorieTarget: "",
  calorieTarget: "",
  errors: {},
};

const CalorieTargetReducer = (state = {}, action) => {
  const calculateKasarskis = (newState) => {
    return kasarskis(
      newState.sex,
      newState.weight,
      newState.height,
      newState.age,
      newState.alsfrs6
    );
  };

  const calculateSuggestedTarget = (newState) => {
    const target = adjustedTarget(
      newState.height,
      newState.weight,
      newState.previousWeight,
      newState.kasarskis,
      newState.validatedCalorieIntake
    );
    return target;
  };

  switch (action.type) {
    case "UPDATE_WEIGHT": {
      let newState = { ...state, weight: action.newWeight };
      newState.kasarskis = calculateKasarskis(newState);
      newState.bmi = bmi(newState.height, newState.weight);
      const suggestedCalorieTarget = calculateSuggestedTarget(newState);
      newState.suggestedCalorieTarget = suggestedCalorieTarget;
      newState.calorieTarget = suggestedCalorieTarget || "";
      return newState;
    }

    case "UPDATE_HEIGHT": {
      let newState = { ...state, height: action.newHeight };
      newState.kasarskis = calculateKasarskis(newState);
      newState.bmi = bmi(newState.height, newState.weight);
      const suggestedCalorieTarget = calculateSuggestedTarget(newState);
      newState.suggestedCalorieTarget = suggestedCalorieTarget;
      newState.calorieTarget = suggestedCalorieTarget || "";
      return newState;
    }

    case "UPDATE_ERRORS": {
      const newErrors = { ...state.errors, [action.key]: action.error };
      const newState = { ...state, errors: newErrors };
      return newState;
    }

    case "UPDATE_ALSFRS_DATA": {
      const newAlsfrs6Data = {
        ...state.alsfrs6Data,
        [action.field]: action.value,
      };

      let newState = {
        ...state,
        alsfrs6Data: newAlsfrs6Data,
      };

      let newAlsfrs6;
      // If all the ALSFRS6 questions have been answered
      if (!Object.values(newAlsfrs6Data).some((score) => score === null)) {
        newAlsfrs6 = Object.values(newAlsfrs6Data).reduce((sum, score) => {
          return sum + Number(score);
        }, 0);
      } else {
        newAlsfrs6 = null;
      }
      newState.alsfrs6 = newAlsfrs6;

      newState.kasarskis = calculateKasarskis(newState);
      const suggestedCalorieTarget = calculateSuggestedTarget(newState);
      newState.suggestedCalorieTarget = suggestedCalorieTarget;
      newState.calorieTarget = suggestedCalorieTarget || "";

      return newState;
    }

    case "UPDATE_CALORIE_TARGET": {
      const newState = { ...state, calorieTarget: action.newCalorieTarget };
      return newState;
    }

    case "UPDATE_VALIDATED_CALORIE_INTAKE": {
      let newState = {
        ...state,
        validatedCalorieIntake: action.newValidatedCalorieIntake,
      };
      const suggestedCalorieTarget = calculateSuggestedTarget(newState);
      newState.suggestedCalorieTarget = suggestedCalorieTarget;
      newState.calorieTarget = suggestedCalorieTarget || "";
      return newState;
    }

    case "UPDATE_LATEST_WEIGHT_ENTRY": {
      if (action.latestWeightEntry) {
        return {
          ...state,
          previousWeight: action.latestWeightEntry,
        };
      }
    }
  }

  return state;
};

export default CalorieTargetReducer;
