const PreStudyWeightEntriesReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_PRE_STUDY_WEIGHT_ENTRY": {
      let newState = [...state];
      newState[action.index] = {
        ...newState[action.index],
        [action.key]: action.value,
      };
      return newState;
    }

    case "UPDATE_WEIGHT_ENTRY_ERRORS": {
      let newState = [...state];
      let newEntry = newState[action.index];
      let newErrors = newEntry.errors;

      newErrors[action.key] = action.errorMessage;
      newEntry.errors = newErrors;
      newState[action.index] = newEntry;
      return newState;
    }
  }
  return state;
};

export default PreStudyWeightEntriesReducer;
