export const positiveAndLessThan = (lessThan, number) => {
  return withinRange(0, lessThan, number);
};

export const betweenZeroAnd = (lessThan, number) => {
  return withinRange(-1, lessThan, number);
};

export const withinRange = (minimum, maximum, number) => {
  const parsed = Number(number);
  return parsed > minimum && parsed < maximum;
};
