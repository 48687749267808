import { processRequest } from "services/api/base_requests";
import {
  createCalorieTarget,
  createPartialCalorieTarget,
} from "services/api/calorie_target_requests.js";

export const CalorieTargetMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    switch (action.type) {
      case "SET_CALORIE_TARGET": {
        const request = createCalorieTarget(action.url, action.form);
        const success = (responseJson) => {
          window.location.href = responseJson.redirectUrl;
        };
        processRequest(request, success);
        break;
      }

      case "SET_PARTIAL_CALORIE_TARGET": {
        const request = createPartialCalorieTarget(
          action.url,
          action.weight,
          action.height,
          action.validatedCalorieIntake
        );
        const success = (responseJson) => {
          window.location.href = responseJson.redirectUrl;
        };
        processRequest(request, success);
        break;
      }
    }

    return next(action);
  };
