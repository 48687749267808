import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AwesomeDebouncePromise from "awesome-debounce-promise";

import { updateWeight, updateErrors } from "actions/calorie_target.actions";
import { positiveAndLessThan } from "services/validators";

import FormInput from "components/form_input";

const Weight = ({
  weight,
  updateWeight,
  weightErrors,
  updateErrors,
  staffRole,
}) => {
  const _validate = (newWeight) => {
    if (positiveAndLessThan(1000, newWeight)) {
      updateErrors("weight", null);
    } else {
      updateErrors("weight", "Must be between 1 and 999");
    }
  };
  const validate = AwesomeDebouncePromise(_validate, 300);

  const handleChange = (e) => {
    const value = e.target.value.replace(/\s/g, "");
    updateWeight(value);
    validate(value);
  };

  return (
    <FormInput
      name="weight"
      label="Weight (kg):"
      labelClass="strong"
      className="short"
      value={weight || ""}
      onChange={handleChange}
      error={weightErrors}
      readOnly={staffRole === "hcp"}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    weight: state.calorieTarget.weight,
    weightErrors: state.calorieTarget.errors.weight,
    staffRole: state.calorieTarget.staffRole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateWeight: updateWeight,
      updateErrors: updateErrors,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Weight);
