import React from "react";
import * as Sentry from "@sentry/browser";

export default class SentryErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === "production") {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
        window.location = "/error";
      });
    }
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    } else {
      return "";
    }
  }
}
